<!--eslint-disable-->
<template>
  <div class="roles-comp">
    <b-card class="" title="Manage Role">
      <b-card-text>This page allows you to view and manage all permissions for your role.</b-card-text>
    </b-card>
    <b-card class="" v-if="!loading">
      <section v-if="role">
        <h4 class="mb-1">Role: {{ role.name }}</h4>

        <section v-if="$can('Update', 'Role') && !role.system">
          <p class="font-weight-bolder">Edit Role name</p>
          <b-form-input class="mb-2"
                        v-model="role.name" placeholder="Enter role name" required></b-form-input>
        </section>

        <p class="font-weight-bolder">Permissions for your role</p>

        <b-card class="" no-body>
          <b-table
            class="data-table"
            hover
            empty-text="No Networks available"
            :items="permissionGroups"
            :fields="headers">
            <template #cell(permissions)="data">
              <div class="row">
                <label v-for="permission in data.item.permissions" :key="permission.id"
                       class="d-flex align-items-center col-2">
                  <b-form-checkbox v-if="!role.system && $can('Update', 'Role')" switch
                                   v-model="role.permission_ids"
                                   :value="permission.id"
                  ></b-form-checkbox>
                  {{ permission.action }}
                </label>
              </div>
            </template>
          </b-table>
        </b-card>

        <section class="d-flex full-width">
          <div class="ui-spacer"></div>
          <b-btn v-if="!role.system && $can('Delete', 'Role')"
                 @click="$bvModal.show('modal-delete-role')"
                 variant="danger"
                 class="mr-2">Delete role
          </b-btn>
          <b-btn v-if="!role.system && $can('Update', 'Role')"
                 :class="{'disabled': role.name.length === 0}"
                 @click="updateRole(role)"
                 class="btn-success">Update role
          </b-btn>

        </section>

      </section>
      <section v-else>
        <p>No Role found</p>
      </section>
    </b-card>

    <b-spinner v-else variant="primary" label="Loading token details"></b-spinner>

    <b-modal id="modal-delete-role" title="Delete role" hide-footer>
      <delete-modal
        v-if="role"
        @close="closeModals()" @delete="deleteRole(role)"
        note="The role will be removed from any users attached"
        :subtitle="role.name"
        title="Are you sure you wish to delete role"></delete-modal>
    </b-modal>
  </div>
</template>
<!--eslint-enable-->

<script>
import { mapState } from 'vuex'
import RolesService from '@/services/RolesService'
import DeleteModal from '@/components/modals/DeleteModal.vue'

export default {
  name: 'RoleView',
  components: {
    DeleteModal,
  },
  props: {
    id: {
      required: true,
      type: String || Number,
    },
  },
  data() {
    return {
      selectedRole: '',
      loading: true,
      roles: [],
      permissions: [],
      permissionGroups: [],
      headers: [
        {
          key: 'subject',
          label: 'Subject',
        },
        {
          key: 'permissions',
          label: 'Permissions',
        },
      ],
    }
  },
  computed: {
    ...mapState({
      role: state => state.role.role,
    }),
  },
  mounted() {
    this.getRole()
    this.getPermissions()
  },
  methods: {
    async getRole() {
      this.loading = true
      await this.$store.dispatch('role/getRole', this.id)
      this.loading = false
    },
    refreshRoles() {
      this.closeModals()
      this.getRole()
    },
    closeModals() {
      this.$bvModal.hide('modal-add-role')
      this.$bvModal.hide('modal-delete-role')
    },
    getPermissions() {
      RolesService.getPermissions()
        .then(res => {
          this.permissions = res.data
          this.sortPermissions()
          this.putPermissionsInGroups()
        })
        .catch(err => {
          const res = err.response
          let errorText = 'Could not get permissions, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
    },
    sortPermissions() {
      this.permissions.sort((a, b) => {
        if (a.subject < b.subject) {
          return -1
        }
        if (a.subject > b.subject) {
          return 1
        }
        return 0
      })
    },
    putPermissionsInGroups() {
      this.permissionGroups = []

      this.permissions.forEach(perm => {
        const index = this.permissionGroups.findIndex(item => item.subject === perm.subject)

        if (index > -1) {
          this.permissionGroups[index].permissions.push(perm)
        } else { // does not exist, create new
          this.permissionGroups.push({
            subject: perm.subject,
            permissions: [perm],
          })
        }
      })
    },
    getRoleDetails() {
      RolesService.getRole(this.selectedRole.id)
        .then(res => {
          this.role = res.data

          if (!this.role.permission_ids) { // set to array if null
            this.role.permission_ids = []
          }
        })
        .catch(err => {
          const res = err.response
          let errorText = 'Could not get roles, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
    },
    updateRole(role) {
      RolesService.updateRole(role.id, {
        name: role.name,
        permissions: role.permission_ids,
      })
        .then(() => {
          this.$toast.success(`Updated role ${role.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          })

          this.refreshRoles()
        })
        .catch(err => {
          const res = err.response
          let errorText = 'Could not update role, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
    },
    deleteRole(role) {
      RolesService.deleteRole(role.id)
        .then(() => {
          this.$toast.success(`Deleted role ${role.name} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          })

          this.$router.push({ name: 'roles' })
        })
        .catch(err => {
          const res = err.response
          let errorText = 'Could not delete role, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
    },
  },
}
</script>

<style>

</style>
